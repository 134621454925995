import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "../components/checkOut/checkOutForm";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import countries from "i18n-iso-countries";
import en from "i18n-iso-countries/langs/en.json";
const stripePromise = loadStripe(
  "pk_test_51Nj1prHBVpkHO6qhTuxI1N2C70aFbIf5AQIWEp632p1ZsqGUhwWLczKG1Ufq2ORIaykGSwlcUZll135Bjo4esVlf00z7szab3n"
);

const PaymentPage = () => {
  const [country, setCountry] = useState("");
  const [name, setName] = useState("");
  const [countriesList, setCountriesList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    countries.registerLocale(en);
    const langCountries = countries.getNames("en");
    const allCountries = Object.keys(langCountries).map((code) => {
      return {
        name: langCountries[code],
        code: code,
      };
    });

    setCountriesList(allCountries);
  }, []);

  return (
    <div
      id="payment-page"
      style={{
        padding: "110px 0 0 0",
        minHeight: "100vh",
        display: "flex",
      }}
    >
      <div className="container d-flex flex-row">
        <div className="payment-column col-4">
          <h5>Billing Information</h5>
          <div className="content">
            <div className="mb-3">
              <label htmlFor="coutnry-option-input">Country</label>
              <select
                id="country-option-input"
                className="form-select"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
              >
                <option hidden disabled selected value="">
                  Choose country...
                </option>
                {countriesList.length &&
                  countriesList.map((country) => (
                    <option
                      data-countrycode={country.code}
                      data-tokens={`${country.code} ${country.name}`}
                      value={country.code}
                    >
                      {country.name}
                    </option>
                  ))}
              </select>
            </div>
            <div>
              <label htmlFor="full-name-input">Full Name</label>
              <input
                id="full-name-input"
                className="form-control"
                placeholder="Enter your name..."
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="payment-column col-4">
          <h5>Payment Method</h5>
          <div className="content d-flex flex-column gap-3">
            <div className="payment-options">
              <div>Credit/Debit Card</div>
              <div>PayPal</div>
              <div>Google Pay</div>
            </div>
            <div className="info">
              <p>Secure payment with SSL Encryption.</p>
              <p
                style={{
                  fontSize: "14px",
                }}
              >
                Having trouble with your payment? Please click here.
              </p>
            </div>
          </div>
        </div>
        <div className="payment-column col-4">
          <h5>Order Details</h5>
          <div className="content">
            <h6>Club Baby Premium</h6>
            <p>Number of users</p>
            <p>Monthly subscription</p>
            <p>Total</p>
            <p>Do you have a promo code?</p>
            <button className="btn btn-primary">Confirm</button>
            <p
              style={{
                fontSize: "14px",
              }}
              className="text-center"
            >
              Your subscription will renew automatically every month as one
              payment of 9.99 USD. Cancel it anytime from your subscription
              settings.
              <br />
              By clicking "Confirm" you agree to the
              <br />
              <a
                onClick={() => {
                  navigate("/tos");
                }}
                className="clickable link"
              >
                Terms and Conditions
              </a>
              .
            </p>
          </div>
        </div>
      </div>
      {/* <Elements stripe={stripePromise}>
        <CheckoutForm />
      </Elements> */}
    </div>
  );
};

export default PaymentPage;
