import { useNavigate, useResolvedPath } from "react-router-dom";
import ExcludeNavbar from "../components/ExcludeNavbar";
import PricingCont from "../components/membership/PricingCont";
import useAuth from "../hooks/useAuth";

const Membership = () => {
  const patha = useResolvedPath();
  const navigate = useNavigate();
  const { auth } = useAuth();
  const STRIPE_PUBLISHABLE_KEY =
    "pk_test_51Nj1prHBVpkHO6qhTuxI1N2C70aFbIf5AQIWEp632p1ZsqGUhwWLczKG1Ufq2ORIaykGSwlcUZll135Bjo4esVlf00z7szab3n";
  const STRIPE_SECRET_KEY =
    "sk_test_51Nj1prHBVpkHO6qhtomOFyywXTWizBAQSDR3O8CEozeaY4GCzrxyAIWWkTIqHVhv8UUzFTv0s9XqDJSDFtpKggFU00Tf46jmrM";

  const pricingOptions = [
    {
      period: "Monthly",
      price: "9.99",
      noun: "month",
      description: "One payment of $9.99, recurring every one month.",
    },
    {
      period: "Yearly",
      price: "100.00",
      noun: "year",
      description: "One payment of $100.00, recurring every twelve month.",
    },
  ];

  return (
    <div
      style={{
        backgroundColor: "#E8E8E8",
      }}
    >
      <div
        className="membership"
        style={{
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "calc(110px + 25px) 25px 25px 25px",
          gap: "15px",
        }}
      >
        <div
          style={{
            width: "fit-content",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <h1
            style={{
              fontFamily: "BubbleGum Sans",
              width: "fit-content",
            }}
          >
            Join Now!
          </h1>
          <h4
            style={{
              fontFamily: "Poppins",
              width: "fit-content",
            }}
          >
            Choose a plan
          </h4>
        </div>
        <div id="container">
          <div id="membership-plans">
            {pricingOptions.map((option) => (
              <PricingCont {...option} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Membership;
