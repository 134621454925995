import GamesFilters from "../components/games/Filters";
import WorksheetsFilters from "../components/worksheets/Filters";
import GameCarouselItem from "../components/Home/GameCarouselItem";
import WorksheetCarouselItem from "../components/Home/WorksheetCarouselItem";
import "slick-carousel";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useEffect, useRef, useState } from "react";
import HashLink, { sections } from "../components/Home/HashLink";
import TestimonialCarouselItem from "../components/Home/TestimonialCarouselItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StarsGroup from "../components/Home/StarsGroup";
import MusicAlbumCarousel from "../components/Home/MusicAlbumCarousel";
import usePrivateRoute from "../hooks/useAxiosPrivate";
import { Link } from "react-router-dom";

const Home = () => {
  const [testimonials, setTestimonials] = useState([{}, {}, {}]);
  const [worksheetCounts, setWorksheetCounts] = useState([]);
  const sliderRef = useRef();
  const authRoute = usePrivateRoute();

  const settings = {
    arrows: false,
    dots: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3500,
    centerMode: true,
    centerPadding: "0",
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: "30px",
        },
      },
    ],
  };

  const musicAlbums = [
    {
      name: "Birthday",
      image:
        "https://clubbaby.com/wp-content/uploads/2023/01/Club-Baby-Birthday.png",
    },
    {
      name: "In Our House",
      image:
        "https://clubbaby.com/wp-content/uploads/2023/01/CB-In-our-House-Cassette.png",
    },
    {
      name: "Saves The World",
      image:
        "https://clubbaby.com/wp-content/uploads/2023/01/CB-Saves-the-world.png",
    },
    {
      name: "Mr. Moon's Lullabies",
      image:
        "https://clubbaby.com/wp-content/uploads/2023/01/CB-Mr.-Moon-Lullabies.png",
    },
    {
      name: "A Special Gift",
      image:
        "https://clubbaby.com/wp-content/uploads/2023/01/A-Special-Gift.png",
    },
    {
      name: "Carousel",
      image: "https://clubbaby.com/wp-content/uploads/2023/01/CB-Carousel.png",
    },
    {
      name: "Chocolate Chip Cookie",
      image:
        "https://clubbaby.com/wp-content/uploads/2023/01/CB-Chocolate-Chip-Cookie.png",
    },
    {
      name: "The Greatest Show Of All Part 1",
      image:
        "https://clubbaby.com/wp-content/uploads/2023/01/CB-The-greatest-show-of-all-P1.png",
    },
    {
      name: "The Greatest Show Of All Part 2",
      image:
        "https://clubbaby.com/wp-content/uploads/2023/01/CB-The-greatest-show-of-all-P2.png",
    },
  ];

  useEffect(() => {
    authRoute.get(`/publictestimonials`).then((response) => {
      setTestimonials(response.data);
    });
  }, []);

  useEffect(() => {
    authRoute.get(`/publicpdfs/countByGrade`).then((response) => {
      const subjects = Object.values(response.data).reduce((obj, type) => {
        obj[type.grade] = type.count;
        return obj;
      }, {});
      setWorksheetCounts(subjects);
    });
  }, []);

  return (
    <div
      id="home-page"
      className="user-page-content mx-0"
      style={{
        background: "#E8E8E8",
      }}
    >
      <img
        src="/homeScreen/NavBarPaint.png"
        style={{
          position: "fixed",
          top: "109px",
          zIndex: "100",
          width: "100vw",
        }}
      />
      <section id="welcome">
        <div
          className="d-flex pt-4"
          style={{
            padding: "0 10%",
            background: "#5980DB",
            color: "white",
          }}
        >
          <div className="flex-grow-1 me-3 d-flex flex-column justify-content-center gap-15">
            <h1>Welcome to CLUB BABY EDU</h1>
            <p>
              Join us at Club Baby EDU, where we offer a diverse range of
              educational resources for children up to grade 3. Club Baby EDU is
              dedicated to high quality educational content that fosters respect
              for our planet and all its residents.
              <br />
              Join Club Baby EDU today and discover what the buzz is all about!
            </p>
            <button
              style={{
                width: "max-content",
              }}
              className="btn btn-light fw-bold"
            >
              Join Free
            </button>
          </div>
          <div
            className="ms-3 d-flex align-items-center justify-content-center"
            style={{
              marginTop: "40px",
            }}
          >
            <div
              style={{
                width: "350px",
              }}
            >
              <img className="w-100" src="/homeScreen/student-and-desk.png" />
            </div>
          </div>
        </div>
        <div style={{ height: "130px", position: "relative" }}>
          <svg
            viewBox="0 0 500 150"
            preserveAspectRatio="none"
            style={{ height: "100%", width: "100%" }}
          >
            <path
              d="M 0 92.27 C 243 -43 308 239 500 109.03 L 500 0 L 0 0 Z"
              style={{ stroke: "none", fill: "#5980DB" }}
            ></path>
          </svg>
          <div
            id="backpack-img"
            style={{
              width: "170px",
              position: "absolute",
              top: "-25%",
              left: "25%",
            }}
          >
            <img className="w-100" src="/homeScreen/backpack.png"></img>
          </div>
        </div>
      </section>
      <section
        id="mission-statement"
        className="d-flex justify-content-center align-items-center flex-column gap-15"
        style={{
          padding: "50px 10% 100px 10%",
          textAlign: "center",
          color: "#32309a",
          position: "relative",
        }}
      >
        <h1 className="z-2">Mission Statement</h1>
        <div
          className="fw-semibold"
          style={{
            padding: "0 10%",
          }}
        >
          <p className="position-relative z-1">
            Welcome to Club Baby, where cooperation and collaboration shape the
            future. In a world often overshadowed by self-interest, it's vital
            to remember that empathy is one of our greatest strengths. At Club
            Baby, we believe in nurturing that value in our children, instilling
            in them a sense of responsibility not only to themselves but to each
            other.
          </p>
          <br />
          <p className="position-relative z-1">
            Our mission is to create a community where love, harmony replace
            conflict and division. We are dedicated to addressing the
            significant challenges of our time—climate change, hunger, and
            global inequality. By fostering a culture of mutual support and
            innovative thinking, we aim to equip the next generation with the
            tools they need to build a better world.
          </p>
          <br />
          <p className="position-relative z-1">
            Join us on our journey to cultivate a future where every child grows
            up in a peaceful and supportive environment, empowered to make a
            positive impact. Together, we can ensure that the legacy we leave
            behind is one of hope, resilience, and unity. Welcome to Club Baby -
            where every heart counts, and every voice matters.
          </p>
        </div>
        {[
          {
            style: { top: "5%", left: "10%", width: "300px" },
            src: "/homeScreen/cloud-1.png",
          },
          {
            style: { top: "10%", right: "10%", width: "300px" },
            src: "/homeScreen/cloud-2.png",
          },
          {
            style: { bottom: "0", left: "20%", width: "240px" },
            src: "/homeScreen/cloud-2.png",
          },
          {
            style: { bottom: "10%", right: "10%", width: "240px" },
            src: "/homeScreen/cloud-3.png",
          },
        ].map((cloud, index) => {
          return (
            <img
              key={index}
              id={`cloud-${index + 1}`}
              style={{
                position: "absolute",
                ...cloud.style,
              }}
              className="z-0 cloud"
              src={cloud.src}
            />
          );
        })}
      </section>
      <section className="py-5" id="features">
        <div style={{ height: "130px", position: "relative" }}>
          <svg
            viewBox="-500 -152 500 150"
            preserveAspectRatio="none"
            style={{ height: "100%", width: "100%" }}
          >
            <path
              d="M -0 -92.27 C -243 43 -308 -239 -500 -109.03 L -500 0 L 0 0 Z"
              style={{ stroke: "none", fill: "#5980DB" }}
            ></path>
          </svg>
          <div
            id="pencil-img"
            style={{
              width: "170px",
              position: "absolute",
              top: "-50%",
              left: "15%",
            }}
          >
            <img className="w-100" src="/homeScreen/pencil.png"></img>
          </div>
          <div
            id="bus-img"
            style={{
              width: "250px",
              position: "absolute",
              top: "-50%",
              right: "15%",
            }}
          >
            <img className="w-100" src="/homeScreen/bus.png"></img>
          </div>
        </div>
        <div
          className="d-flex flex-column pb-4 justify-content-center align-items-center"
          style={{
            padding: "0 10%",
            background: "#5980DB",
            color: "white",
          }}
        >
          <h1>Features</h1>
          <Slider
            {...{
              arrows: false,
              dots: false,
              slidesToShow: 4,
              slidesToScroll: 1,
              responsive: [
                {
                  breakpoint: 768,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                  },
                },
              ],
            }}
            className="w-100"
            style={{
              marginBottom: "45px",
            }}
          >
            {sections.map((section, index) => {
              return (
                <HashLink
                  key={index}
                  href={section.href}
                  title={section.title}
                  img={section.img}
                  description={section.description}
                />
              );
            })}
          </Slider>
        </div>
      </section>
      <section
        id="games"
        className="d-flex pt-5 justify-content-center align-items-center flex-column"
        style={{
          padding: "0 10%",
          textAlign: "center",
          scrollMarginTop: "110px",
          position: "relative",
        }}
      >
        <div
          style={{
            width: "130px",
            position: "absolute",
            top: "5%",
            left: "5%",
            rotate: "-15deg",
          }}
        >
          <img className="w-100" src="/homeScreen/game-controller.png"></img>
        </div>
        <h1
          style={{
            color: "#32309a",
          }}
        >
          Games
        </h1>
        <Slider
          {...settings}
          className="w-100"
          style={{
            marginBottom: "45px",
          }}
          ref={sliderRef}
          beforeChange={(currentSlide, nextSlide) => {
            if (nextSlide == GamesFilters.gameTypes.length - 1) {
              setTimeout(function () {
                if (sliderRef.current) {
                  sliderRef.current.slickGoTo(0);
                }
              }, 3000);
            }
          }}
        >
          {GamesFilters.gameTypes.map((gameType) => {
            return (
              <GameCarouselItem key={gameType.value} gameType={gameType} />
            );
          })}
        </Slider>
      </section>
      <section
        id="worksheets"
        className="d-flex pt-4 justify-content-center align-items-center flex-column gap-10"
        style={{
          padding: "0 10%",
          textAlign: "center",
          scrollMarginTop: "110px",
        }}
      >
        <h1
          style={{
            color: "#32309a",
          }}
        >
          Worksheets
        </h1>
        <Slider
          {...{
            arrows: false,
            dots: false,
            slidesToShow: 4,
            slidesToScroll: 1,
            responsive: [
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  dots: true,
                },
              },
            ],
          }}
          className="w-100"
          style={{
            marginBottom: "45px",
          }}
        >
          {WorksheetsFilters.gradeFilters.map((grade, index) => {
            return (
              <WorksheetCarouselItem
                key={index}
                count={worksheetCounts[grade.search]}
                grade={grade}
              />
            );
          })}
        </Slider>
      </section>
      <section
        id="music"
        className="d-flex align-items-center flex-column gap-15"
        style={{
          padding: "2% 10%",
          textAlign: "center",
          scrollMarginTop: "110px",
          position: "relative",
        }}
      >
        <div
          style={{
            width: "130px",
            position: "absolute",
            top: "15%",
            right: "5%",
            rotate: "25deg",
          }}
        >
          <img
            className="w-100 music-chords-img"
            src="/homeScreen/music.png"
          ></img>
        </div>
        <div
          style={{
            width: "130px",
            position: "absolute",
            bottom: "15%",
            left: "5%",
            rotate: "25deg",
          }}
        >
          <img
            className="w-100 music-chords-img"
            src="/homeScreen/music.png"
          ></img>
        </div>
        <h1
          style={{
            color: "#32309a",
          }}
        >
          Music
        </h1>
        <Slider
          {...{
            arrows: false,
            dots: true,
            slidesToShow: 4,
            slidesToScroll: 1,
            responsive: [
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  dots: true,
                },
              },
            ],
          }}
          className="w-100"
          style={{
            marginBottom: "45px",
          }}
        >
          {musicAlbums.map((album, index) => {
            return <MusicAlbumCarousel key={index} album={album} />;
          })}
        </Slider>
      </section>
      <section
        id="our-membership"
        className="d-flex justify-content-center align-items-center flex-column gap-15 py-3"
        style={{
          padding: "0 10%",
          textAlign: "center",
        }}
      >
        <div className="d-flex">
          <div className="col-sm-6">
            <img
              className="w-75 flip-horizontal"
              src="/homeScreen/membership.png"
            />
          </div>
          <div
            className="d-flex flex-column col-sm-6 align-center-small"
            style={{
              color: "#32309a",
              textAlign: "left",
            }}
          >
            <h1>Our Membership</h1>
            <p className="fw-medium">
              Choose the perfect Club Baby EDU membership plan for your family,
              with monthly, yearly, or lifetime options, providing full access
              to all our educational resources.
            </p>
            <div className="membershipContainer d-flex flex-column my-3 gap-3">
              {[
                {
                  period: "Monthly",
                  description: `Our flexible Monthly Membership provides a no-commitment
trial to all our educational resources.`,
                },
                {
                  period: "Yearly",
                  description: `The Yearly Membership offers great value and uninterrupted
access to our complete resource collection.`,
                },
              ].map((membership, index) => {
                return (
                  <Link
                    style={{
                      color: "inherit !important",
                    }}
                    key={index}
                    to={"/membership"}
                  >
                    <div className="membershipExplain flexColumn">
                      <h3
                        style={{
                          fontFamily: "Bubblegum Sans",
                          fontWeight: "normal",
                          justifyContent: "space-between",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {membership.period}
                        <FontAwesomeIcon
                          icon="fa-solid fa-arrow-right"
                          style={{
                            fontSize: "15px",
                          }}
                        />
                      </h3>
                      <p>{membership.description}</p>
                    </div>
                  </Link>
                );
              })}
            </div>
            <Link
              to={"/membership"}
              className="btn"
              style={{
                backgroundColor: "#5980db",
                color: "white",
                padding: "10px 15px",
                width: "max-content",
                alignSelf: "center",
              }}
            >
              Choose a Plan
            </Link>
          </div>
        </div>
      </section>
      <section id="testimonials" className="pt-5">
        <div style={{ height: "100px", position: "relative" }}>
          <svg
            viewBox="0 0 500 150"
            preserveAspectRatio="none"
            style={{
              height: "100%",
              width: "100%",
              backgroundColor: "#32309a",
            }}
          >
            <path
              d="M 0 100 C 135 -0 365 -0 500 100 L 500 -0 L 0 0 Z"
              style={{ stroke: "none", fill: "#e8e8e8" }}
            ></path>
          </svg>
          <div
            id="scissors-img"
            style={{
              width: "130px",
              position: "absolute",
              bottom: "-10%",
              zIndex: "5",
              left: "15%",
              rotate: "90deg",
            }}
          >
            <img className="w-100" src="/homeScreen/scissors.png"></img>
          </div>
          <div
            id="calculator-img"
            style={{
              width: "130px",
              position: "absolute",
              bottom: "-10%",
              zIndex: "5",
              right: "15%",
            }}
          >
            <img className="w-100" src="/homeScreen/calculator.png"></img>
          </div>
        </div>
        <div
          className="w-100 d-flex flex-column justify-content-center align-items-center gap-15"
          style={{
            padding: "0 calc(10% + 70px)",
            background: "#32309a",
            color: "white",
            position: "relative",
            background:
              "url(/homeScreen/testimonials-background.png) no-repeat, #32309a",
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        >
          <h1
            style={{
              color: "#ffffff",
              position: "absolute",
              top: "-40px",
            }}
          >
            Our Happy Parents
          </h1>
          <Slider
            {...{
              arrows: true,
              dots: false,
              slidesToShow: 3,
              slidesToScroll: 1,
              responsive: [
                {
                  breakpoint: 768,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                  },
                },
              ],
            }}
            className="w-100"
          >
            {testimonials.map((testimonial, index) => {
              return (
                <TestimonialCarouselItem
                  key={index}
                  testimonial={testimonial}
                />
              );
            })}
          </Slider>
        </div>
      </section>
      <section>
        <div style={{ height: "100px", position: "relative" }}>
          <svg
            viewBox="-500 -152 500 150"
            preserveAspectRatio="none"
            style={{
              height: "100%",
              width: "100%",
              backgroundColor: "#32309a",
            }}
          >
            <path
              d="M 0 -100 C -135 0 -365 0 -500 -100 L -500 0 L 0 0 Z"
              style={{ stroke: "none", fill: "#5980DB" }}
            ></path>
          </svg>
        </div>
        <div
          className="w-100 d-flex flex-column justify-content-center align-items-center pt-4 pb-5 gap-15"
          style={{
            padding: "0 10%",
            background: "#5980DB",
            color: "white",
            position: "relative",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "-15%",
              left: "5%",
            }}
          >
            <StarsGroup />
          </div>
          <div
            style={{
              position: "absolute",
              bottom: "5%",
              right: "5%",
            }}
          >
            <StarsGroup />
          </div>
          <h1
            style={{
              color: "#ffffff",
            }}
          >
            Need Help? Let's Get In Touch
          </h1>
          <input
            className="form-control"
            placeholder="Type your email here"
          ></input>
          <button
            className="btn"
            style={{
              backgroundColor: "#32309a",
              color: "white",
              padding: "10px 30px",
            }}
          >
            Submit
          </button>
        </div>
      </section>
    </div>
  );
};

export default Home;
