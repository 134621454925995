import {
  Link,
  useResolvedPath,
  useMatch,
  NavLink,
  useLocation,
} from "react-router-dom";
import LoginButton from "../LoginButton";
import HeaderItem from "../header/HeaderItem";
import useAuth from "../../hooks/useAuth";
import HomeBtn from "../HomeBtn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "@fortawesome/fontawesome-free";
import { library } from "@fortawesome/fontawesome-svg-core";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import useLogout from "../../hooks/useLogout";
import "./navbar.css";
library.add(far, fas, fab);

const routes = [
  {
    to: "/admin",
    value: "Dashboard",
    icon: "",
  },
  {
    to: "/admin/games",
    value: "Games",
    icon: "",
  },
  {
    to: "/admin/videos",
    value: "Videos",
    icon: "",
  },
  {
    to: "/admin/worksheets",
    value: "Worksheets",
    icon: "",
  },
  {
    to: "/admin/testimonials",
    value: "Testimonials",
    icon: "",
  },
  {
    to: "/admin/payment-history",
    value: "Payment History",
    icon: "",
  },
  {
    to: "/admin/history",
    value: "History",
    icon: "",
  },
  {
    to: "#",
    value: "Admins",
    icon: "",
    next: [
      {
        to: "/admin/admins",
        value: "Admins List",
        icon: "",
      },
      {
        to: "/admin/admin-create",
        value: "Add New Admin",
        icon: "",
      },
    ],
  },
  {
    to: "#",
    value: "Users",
    icon: "",
    next: [
      {
        to: "/admin/users",
        value: "Users List",
        icon: "",
      },
      {
        to: "/admin/user-create",
        value: "Add New User",
        icon: "",
      },
    ],
  },
  {
    value: "Logout",
    icon: "",
    onClick: useLogout,
  },
];

const HeaderLink = ({ to, children, value, ...props }) => {
  const location = useLocation();
  const isActive = location.pathname === to;

  return (
    <Link className={`headerItem navItem ${isActive ? "active" : ""}`} to={to}>
      <h2>{children}</h2>
    </Link>
  );
};

const CollapsedNavBar = () => {
  const patha = useResolvedPath();
  console.log(patha.pathname);
  const logOut = useLogout();
  const { auth } = useAuth();

  const createMenu = (route) => {
    if (route.to) {
      return (
        <li key={route.value} className="nav-item mt-3 mt-lg-0 nav-item">
          <HeaderLink key={route.value} to={route.to}>
            {route.value}
          </HeaderLink>
          {route.next && (
            <ul className="submenu">{route.next.map(createMenu)}</ul>
          )}
        </li>
      );
    } else if (route.onClick) {
      return (
        <li
          key={route.value}
          className="nav-item mt-3 mt-lg-0 nav-item"
          data-toggle="collapse"
          data-target=".navbar-collapse.show"
        >
          <a className="headerItem navItem">
            <h2 onClick={route.onClick()}>{route.value}</h2>
          </a>
        </li>
      );
    }
  };

  return (
    <nav
      id="collapsed-navbar"
      className="navbar navbar-expand-lg navbar-dark bg-light"
    >
      <Link to={"/"} className="navbar-brand">
        <img src="/logo.svg" />
      </Link>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <div className="row width100">
          <div
            className="flexCenter col-lg-9 col-xl-10"
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <ul className="navbar-nav mr-auto">
              {routes.map(createMenu)}
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

const NavBarLink = ({ to, children }) => {
  const location = useLocation();
  const isActive = location.pathname === to;

  return (
    <Link className={`nav-link ${isActive ? "active" : ""}`} to={to}>
      {children}
    </Link>
  );
};

const NavBar = () => {
  const patha = useResolvedPath();
  console.log(patha.pathname);
  const logOut = useLogout();
  const { auth } = useAuth();

  const createMenu = (route) => {
    if (route.to) {
      return (
        <li key={route.value}>
          <NavBarLink to={route.to}>{route.value}</NavBarLink>
          {route.next && (
            <ul className="submenu">{route.next.map(createMenu)}</ul>
          )}
        </li>
      );
    } else if (route.onClick) {
      return (
        <li key={route.value}>
          <a className="nav-link" onClick={route.onClick()}>
            {route.value}
          </a>
        </li>
      );
    }
  };

  return (
    <nav id="adminNav">
      <ul className="nav flex-column">
        <Link id="home-img" className="nav-link" to="/">
          <img src="/logo.svg" alt="Home" />
        </Link>
        {routes.map(createMenu)}
      </ul>
    </nav>
  );
};

export { NavBar, CollapsedNavBar };
