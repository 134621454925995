import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { NavBar, CollapsedNavBar } from "../components/newNavbar/navbar";
import VideoUploadModal from "../components/videos/VideoUploadModal/VideoUploadModal";
import * as bootstrap from "bootstrap";


const AdminVideos = () => {
  const navigate = useNavigate();

  const [newVideo, setNewVideo] = useState(null);
  const [editedVideo, setEditedVideo] = useState(null);
  const showModal = () => {
    const myModal = bootstrap.Modal.getInstance(
      document.getElementById("admin-upload-video-modal")
    );
    myModal.show();
  };

  return (
    <div id="page-container">
      <NavBar />
      <CollapsedNavBar />
      <div id="page-content">
        <button
          type="button"
          className="btn"
          style={{
            backgroundColor: "#845adf",
            color: "white",
            fontWeight: 700,
            position: "absolute",
            top: 0,
            right: 0,
            margin: "20px 40px 0 0",
          }}
          onClick={showModal}
        >
          Add New Video
        </button>
        <VideoUploadModal
          editedVideo={editedVideo}
          setNewVideo={setNewVideo}>
        </VideoUploadModal>
      </div>
    </div >
  )
}
export default AdminVideos